import React from 'react'
import { Helmet } from 'react-helmet'

import * as config from '../config'
import useSiteMetadata from '../hooks/useSiteMetadata'

interface Props {
  title?: string
  description?: string
  pathname?: string
}

const SiteHead = ({ title, description, pathname }: Props) => {
  const { title: defaultTitle, description: defaultDescription, siteUrl, image } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    image: `${siteUrl}${image}`,
  }

  const shouldDebugScreens = config.STAGE === 'dev' || config.STAGE === 'stg'

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:image" content={seo.image} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={seo.url} />
      <meta name="og:site_name" content={seo.title} />
      <meta property="og:locale" content="es_ES" />
      <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />
      <body data-app-version={config.APP_VERSION} className={shouldDebugScreens ? 'debug-screens' : ''} />
    </Helmet>
  )
}

export default SiteHead
