import { createContext } from 'react'

import { AuthActions } from './reducer'

export const AutoLoginSectionsURLs = {
  catalog: '/app/products',
  suggested_catalog: '/app/products?sortby=suggested',
  historical_catalog: '/app/products?sortby=historical',
  promotions_catalog: '/app/products?promotions=true',
  repeat_order: '/app/my-account/orders',
  favorites: '/app/favorites',
  invoices: '/app/my-account/invoices',
  orders: '/app/my-account/orders',
  support: '/app/support',
  shopping_cart: '/app/shopping-cart',
  default: '/app',
}

export const AuthState = {
  isAuth: false,
  signInLoading: false,
  email: '',
  phone: '',
  dni: '',
  id: '',
  name: '',
  defaultStoreId: '',
  stores: [],
  needPasswordUpdate: true,
  canEditStoreData: false,
  canEditUserData: false,
  canViewCredit: false,
  test: false,
  onboarding: {
    mandatoryModal: false,
    products: false,
    cart: false,
    checkout: false,
  },
  visibleStores: [] as number[],
  dutyFreeStores: [] as number[],
  inviteeRequestId: undefined as string | undefined,
  origin: '',
  referenceType: '',
}

export type AuthState = Omit<typeof AuthState, 'dutyFreeStores'> & { dutyFreeStores?: number[] }

export const AuthContext = createContext<AuthState>(AuthState)

export type AuthDispatch = (action: AuthActions) => void
export const DispatchContext = createContext<AuthDispatch | undefined>(undefined)
